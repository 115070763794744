import React, { useState } from "react";
import { Link as RouterLink, useNavigate} from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  InputAdornment,
  IconButton,
  Link,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import peopleImage from "../../assets/people.png";
import AnimateButton from "../../components/@extended/AnimateButton";
import useAuth from '../../hooks/useAuth/index.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthContext } from "../../contexts/UserContext.js";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
  paperLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    height: '68vh',
    width: '64vh',
  },
  paperRight: {
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    height: '68vh',
    width: '64vh',
  },
  peopleImage: {
    borderRadius: theme.spacing(2),
    height: "60%",
    width: "70%",
    border: '1px solid white',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  whatsapp: {
    backgroundColor: '#32d951'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  containerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '150vh',
  },
  mobileContainer: {
    flex: 1,
    display: 'flex',
    maxWidth: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  LoginContainer: {
    display: 'flex',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.paper,
    boxShadow: "5px 0px 6px 6px rgba(0, 0, 0, 0.16)",
  },
  hideOnMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));




const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth } = useAuthContext();
  const { login } = useAuth(); 
  const navigate = useNavigate();

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setTimeout(async () =>{

    
    try {
      const data = await login(user);
      setAuth(data.user);
    
      if (data) {
        navigate("/dashboard");  
      }
    } catch (err) {
      toast.error(
      <div>
        <strong>Erro ao fazer login</strong>
        <div>Email ou senha incorretos</div>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }finally {
      setLoading(false);
    }
    }, 500);
  };

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.containerWrapper}>
          <Container component="div" maxWidth="xs" className={classes.mobileContainer}>
            <span className={classes.LoginContainer}>
              <div className={classes.paperLeft}>
                <h4 style={{ fontSize: 35 }}>Faça seu Login</h4>
                <Divider />
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    helperText="exemplo@gmail.com"
                    name="email"
                    value={user.email}
                    onChange={handleChangeInput}
                    autoComplete="email"
                    autoFocus
                  />
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={user.password}
                      onChange={handleChangeInput}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                  <AnimateButton>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {loading ? ( 
                      <div >
                        <CircularProgress color='textWhite' size={12}/>
                      </div>
                      ) : (
                        "Entrar"
                      )
                      }
                    </Button>
                  </AnimateButton>

                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link
                        variant="body2"
                        component={RouterLink}
                        to="/register"
                      >
                        Registrar
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div className={classes.paperRight}>
                <div className={classes.peopleImage}>
                  <img src={peopleImage} style={{ height: "100%", width: "100%" }} />
                </div>
                <Divider />
                <h3 style={{ color: "#F2F2F2", margin: '19px 65px' }}>Eleve o atendimento de psicologia a outro nível</h3>
              </div>
            </span>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default Login;