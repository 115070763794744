import * as React from 'react';
import {Button, Card, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import useCustomer from '../../hooks/useCustomer/index.js';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from '../../contexts/UserContext.js';

export default function CustomerModal({ onClose, customer, onAdd, onUpdate }) {
  const {save} = useCustomer();
  const {update} = useCustomer();
  const { auth, setAuth } = useAuthContext();
  const [customerData, setCustomerData] = React.useState(customer || {
    name: '',
    email: '',
    celular: '',
    cpf: '',
    status: 'Ativo',
    value: auth.value || 100,
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    customerData.idUser = auth.id;

    try {
      if (customer) {
        toast.success("Success Notification !", {
          position: "top-center"
        });
        const customer = await update(customerData);
        onUpdate(customer);
      } else {
        toast.success("Success Notification !", {
          position: "top-center"
        });
        const customer = await save(customerData);
        onAdd(customer);
      }
      onClose();
    } catch (error) {
      console.error("Error saving customer:", error);
    }
  };

  return (
    <Card style={{ borderRadius: 15, padding: 20, margin: 'auto', maxWidth: 500 }}>
      <Typography variant="h6" gutterBottom>
        {customer ? 'Editar Cliente' : 'Adicionar Cliente'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            label="Nome"
            name="name"
            value={customerData?.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            size='small'
            label="Celular"
            name="celular"
            placeholder='(00) 00000-0000'
            value={customerData?.celular}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Email"
            name="email"
            size='small'
            placeholder='paciente@email.com'
            value={customerData?.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="CPF"
            size='small'
            name="cpf"
            placeholder='000.000.000-00'
            value={customerData?.cpf}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" size='small' fullWidth>
            <InputLabel id="status-outlined-label">Status</InputLabel>
            <Select
              labelId="status-outlined-label"
              id="status-select-outlined"
              name="status"
              label="Status"
              value={customerData?.status}
              onChange={handleChange}
            >
              <MenuItem value="Ativo">Ativo</MenuItem>
              <MenuItem value="Inativo">Inativo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} style={{ marginRight: 10 }}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
