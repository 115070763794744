import PropTypes from 'prop-types';

// material-ui
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// project import

// assets
import RiseOutlined from '@ant-design/icons/RiseOutlined';
import FallOutlined from '@ant-design/icons/FallOutlined';
import MainCard from '../MainCard';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  
    iconSX :{ 
        fontSize: '0.75rem', 
        color: 'inherit', 
        marginLeft: 0, 
        marginRight: 0 
    },
    
}));


export default function AnalyticEcommerce({ color = 'primary', title, count, percentage, isLoss, extra }) {
    const classes = useStyles()
  const theme = useTheme();
  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Container spacing={1.0}>
        <Typography variant="h6" style={{color: theme.palette.cardTitle, fontWeight: 400, fontSize: '0.875rem'}} >
          {title}
        </Typography>
        <Grid container alignItems="center"  style={{gap:10}}>
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600}}  color="inherit">
              {count}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </MainCard>
  );
}


