import React, { createContext, useState, useContext } from 'react';
const NotificationContext = createContext();

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [newNotification, setNewNotification] = useState(false);
  
    return (
      <NotificationContext.Provider value={{ newNotification, setNewNotification }}>
        {children}
      </NotificationContext.Provider>
    );
};