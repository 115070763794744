import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MainCard from '../../components/MainCard';
import { useTheme } from '@material-ui/core';
import { useAuthContext } from '../../contexts/UserContext.js';

const user = {
    name: 'Pedro Rocha',
    firstName: 'Pedro',
    lastName: 'Rocha',
    avatar: '/assets/avatar.png',
    jobTitle: 'Senior Developer',
    country: 'BR',
    city: 'Belo Horizonte',
    timezone: 'GMT-5',
};
const cardStyle = {
    borderRadius: 45
}
export function AccountInfo() {
    const theme = useTheme();
    const { auth, setAuth } = useAuthContext();
    console.log("ayuth ",auth)
    return (
        <MainCard contentSX={cardStyle} >
            <Grid container alignItems='center' spacing={2} justifyContent='center' direction='column'>
                <Grid item >
                    <Avatar src={user.avatar} style={{ height: '80px', width: '80px', fontSize: 35 }}>{auth.name.charAt(0)}{auth.name.charAt(1)}</Avatar>

                </Grid>
                <Grid item container spacing={1} direction='column' style={{ textAlign: 'center' }}>
                    <Grid item >
                        <Typography variant="h5" style={{ fontWeight: 500 }} >{auth.name}</Typography>

                    </Grid>
                    <Grid item >
                        <Typography style={{color: theme.palette.textSecondary}}  variant="body2">
                            {auth.city}, {auth.country}
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Typography style={{color: theme.palette.textSecondary}} variant="body2">
                            {user.timezone}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Divider style={{ marginTop: 15 }} />
            <CardActions style={{marginBottom:-15}} >
                <Button fullWidth variant="text" color='primary' >
                    Alterar Foto de Perfil
                </Button>
            </CardActions>
        </MainCard>
    );
}
