import React, {  useEffect, useReducer, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  makeStyles,
  
} from "@material-ui/core";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CalendarIcon from '@material-ui/icons/Event';
import AtendimentosIcon from "@material-ui/icons/ChatBubbleOutline"
import RespostasRapidasIcon from '@material-ui/icons/Speed';
import Divider from "@material-ui/core/Divider";
import ContatosIcon from  '@material-ui/icons/PermContactCalendar';


const useStyles = makeStyles((theme) => ({
  
  groupNavigationText:{
     color: theme.palette.textGray,
     marginLeft: 20,
     fontWeight: 600
  },
  listItem: {
    color: "#b3b9c6",
    minWidth: 40
  },
  campaignItem :{
   color: theme.mode === 'light' ? '#ffff' : '',
  },
  
  listItemButton: {
    height:40,
    '&:hover': {
      backgroundColor: theme.palette.primary.main, // Escolha a cor de fundo desejada para o hover
      color: theme.palette.textPrimary,

      borderRadius: '7px',
    },
    '&:hover $listItem': {
      backgroundColor: theme.palette.hover, // Altere para a cor desejada
      color: theme.palette.textPrimary,
    },
    '$listItem': {
      background: theme.palette.hover, // Altere para a cor desejada
      color: theme.palette.textPrimary,

    },
    color: "#b3b9c6",

    transition: 'none',
    borderRadius: '10px',
    marginBottom: '10px',
    width: '16.5vw',
  },

  selectedListItem :{
      backgroundColor: `${theme.palette.primary.main} !important`, 
      color: theme.palette.textPrimary,
      borderRadius: '7px',
      '& $listItem': {
          color: theme.palette.textPrimary,
      },
  },
  listText:{
    "& span": 
    { 
      fontWeight: 600, 
    
    } 
  }
}));

function ListItemLink(props) {
  const { icon, primary, to, selected, onClick } = props;
  const classes = useStyles();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );
  return (
    <li>
      <ListItem button selected={selected} onClick={onClick} component={renderLink}   className={selected ? `${classes.listItemButton} ${classes.selectedListItem}` : classes.listItemButton}>
        {icon ? <ListItemIcon className={classes.listItem}>{icon}</ListItemIcon> : null}
        <ListItemText className={classes.listText} primary={primary} />
      </ListItem>
    </li>
  );
}


const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose } = props;
 

  const [selectedIndex, setSelectedIndex] = useState(() => {
    const savedIndex = localStorage.getItem('selectedIndex');
    return savedIndex ? parseInt(savedIndex, 10) : 1;
  });

  useEffect(() => {
    localStorage.setItem('selectedIndex', selectedIndex);
  }, [selectedIndex]);


  const handleListItemClick = (index) => {
    setSelectedIndex(index);
 };
  const urlPrefix = ""
  return (
    <div onClick={drawerClose}>
       <Typography variant="subtitle2"  className={classes.groupNavigationText}>
              Manutenção
       </Typography>
       <Divider style={{marginBottom: 10}}/>

      <ListItemLink
        to={`${urlPrefix}/dashboard`}
        primary="Dashboard"
        icon={<AtendimentosIcon />}
        selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1)}
        />
        <Divider style={{marginBottom: 25}}/>
        <Typography variant="subtitle2"  className={classes.groupNavigationText}>
              Usabilidade
       </Typography>
       <Divider style={{marginBottom: 10}}/>
      <ListItemLink
        to={`${urlPrefix}/clientes`}
        primary="Pacientes"
        icon={<RespostasRapidasIcon />}
        selected={selectedIndex === 2}
            onClick={() => handleListItemClick(2)}
      />
      <ListItemLink
        to={`/clinica`}
        primary="Clínica"
        icon={<ContatosIcon />}
        selected={selectedIndex === 7}
            onClick={() => handleListItemClick(7)}
      />
      <ListItemLink
        to={`${urlPrefix}/agenda`}
        primary="Agenda"
        icon={<CalendarIcon />}
        selected={selectedIndex === 4}
            onClick={() => handleListItemClick(4)}
      />
      <Divider style={{marginBottom: 25}}/>
      <Typography variant="subtitle2"  className={classes.groupNavigationText}>
              Adminstração
       </Typography>
       <Divider style={{marginBottom: 10}}/>
      <ListItemLink
        to={`${urlPrefix}/financial`}
        primary="Financeiro"
        icon={<AttachMoneyIcon />}
        selected={selectedIndex === 3}
            onClick={() => handleListItemClick(3)}
      />
      <ListItemLink
        to={`${urlPrefix}/superAdmin`}
        primary="Super Admin"
        icon={<SettingsIcon />}
        selected={selectedIndex === 5}
            onClick={() => handleListItemClick(5)}
      />
      <ListItemLink
        to={"configuracoes"}
        primary="Configurações"
        icon={<PermDataSettingIcon />}
        selected={selectedIndex === 6}
            onClick={() => handleListItemClick(6)}
      />
      
    </div>
  );
};

export default MainListItems;
