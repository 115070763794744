import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Box, Divider, makeStyles, useTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ChangePassword } from '../configuration/ChangePassword';
import { AccountInfo } from '../configuration/AccountInfo';
import { AccountDetails } from '../configuration/AccountDetails';
import News from './PsychologistNews';
import CustomerNews from './CustomerNews';
import PsychologistNews from './PsychologistNews';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Inter',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height:"100%"
  },

  padding: {
    padding: theme.spacing(2),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  dataTitle: {
    fontWeight: 700,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height:"100%"}}
    >
      {value === index && (
        <Box p={3} style={{height:"100%"}} >
          <Typography style={{height:"90%"}}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SystemNews() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid spacing={3} style={{height:"100%"}} >

      <div className={classes.root}>
        <div className={classes.demo1}>
          <Grid container style={{padding:12, gap: 5}}>
           <NewReleasesIcon />
            <Typography variant="subtitle1" className={classes.dataTitle} >Painel de Atividade</Typography>
          </Grid>
          <Divider />

          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Atualizações" />
            <AntTab label="Atividades de Clientes" />
          </AntTabs>
        </div>
        <TabPanel value={value} index={0} >
            <Grid item xs={12} style={{height:"100%"}}>
              <PsychologistNews />
            </Grid>

        </TabPanel>
        <TabPanel value={value} index={1}>
            <CustomerNews />

        </TabPanel>
      </div>



    </Grid>
  );
}
