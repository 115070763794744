import { Grid, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  grey, white } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import PageviewIcon from '@material-ui/icons/Pageview';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';



const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
      },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        color: grey[100],
        '&:hover':{
            color: theme.palette.primary.main
        }
    },
    closeButton: {
        backgroundColor: theme.palette.secondary.main,
        color: grey[100],
        '&:hover':{
            color: theme.palette.secondary.main
        }
    },
}));

function SearchPeopleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
        <List>
          {emails.map((email) => (
            <ListItem button onClick={() => handleListItemClick(email)} key={email}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
            </ListItem>
          ))}
  
          <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem>
        </List>
      </Dialog>
    );
  }

export default function AddPeopleEvent() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
      };
    const handleRemovePeople =() => {
         setSelectedValue(null)
    }

    return (
        <>
        <Grid container>
            {!selectedValue ?
            (
                <IconButton onClick={handleClickOpen}   className={classes.addButton}>
                <AddIcon fontSize='small' />
                </IconButton >
            ):
            (
            <>
            <Grid container style={{gap: 5}}>
                <Avatar className={classes.avatar}>
                    T
                </Avatar>
                <Tooltip title="Remover">   
                    <IconButton onClick={handleRemovePeople}   className={classes.closeButton}>
                        <CloseIcon fontSize='small' />
                        </IconButton > 
                </Tooltip>

            </Grid>

              </>
            )
            }

        </Grid>
        <SearchPeopleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
        </>
    )
}