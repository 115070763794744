import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, Box ,Dialog, DialogActions, Typography, DialogContent, DialogTitle, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid',
        borderLeftWidth: 6,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        height: '100%',
        position: 'relative',
        borderColor: '#4caf50', // Cor da borda
        },
        iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        },
        icon: {
        fontSize: 48, // Tamanho maior do ícone
        color: '#4caf50', // Cor do ícone
        backgroundColor: '#EDEDED',
        padding: theme.spacing(1),
        borderRadius: '50%',
        },
        contentContainer: {
        display: 'flex',
        alignItems: 'center',
        },
        titleAmountContainer: {
        marginLeft: theme.spacing(2),
        },
        title: {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#4caf50',
        },
        amount: {
        fontSize: '1.8rem',
        fontWeight: 700,
        color: '#333', 
        },
        footer: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '1.15rem',
        color: '#888',
        },
        title: {
        fontSize: '1rem',
        fontWeight: 600,
        },
        amount: {
        fontSize: '18px',
        fontWeight: 700,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        },
        subtitle: {
        fontSize: '0.875rem',
        color: '#888',
        },
        controlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        },
        periodContainer: {
        display: 'flex',
        alignItems: 'center',
        },
        periodText: {
        marginRight: theme.spacing(1),
        fontWeight: 600,
        },
        formControl: {
        minWidth: 120,
        marginLeft: theme.spacing(1),
        },
        button: {
        marginLeft: theme.spacing(2),
        },
        iconButton: {
        marginLeft: theme.spacing(1),
        },
        chartContainer: {
        padding: theme.spacing(3),
        background: '#f7f7f7',
        borderRadius: 4,
        marginTop: theme.spacing(3),
        },
        dataTitle: {
        marginTop: theme.spacing(2),
        fontWeight: 600
        },
    tableHead: {
        fontWeight: 'bold',
        fontFamily:'Inter',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.textPrimary,

    },
    tableCell: {
        fontSize: '18px',
        fontFamily:'Inter',
    },
}));

const columns = [
  { id: 'name', label: 'Psicólogo', minWidth: 100, align: 'left' },
  { id: 'code', label: 'Código', minWidth: 100, align: 'left' },
  { id: 'cpf', label: 'CPF', minWidth: 100, align: 'left' },
  { id: 'effectiveDate', label: 'Data de entrada', minWidth: 100, align: 'left' },
  { id: 'value', label: 'Valor', minWidth: 100, align: 'left' },
  { id: 'active', label: 'Ativo', minWidth: 100, align: 'left' },
];

const loadrows = [
  { id: 1, name: 'João', code: '123', cpf: '123.456.789-00', effectiveDate: '2021-10-10', value: 'R$ 100,00', active: true },
  { id: 2, name: 'Maria', code: '456', cpf: '987.654.321-00', effectiveDate: '2023-05-07', value: 'R$ 100,00', active: true },
  { id: 3, name: 'José', code: '789', cpf: '123.456.789-00', effectiveDate: '2024-04-18', value: 'R$ 100,00', active: true },
  { id: 4, name: 'Ana', code: '101', cpf: '987.654.321-00', effectiveDate: '2020-05-09', value: 'R$ 100,00', active: false },
  { id: 5, name: 'Pedro', code: '112', cpf: '123.456.789-00', effectiveDate: '2022-08-15', value: 'R$ 100,00', active: true },
  { id: 6, name: 'Marta', code: '131', cpf: '987.654.321-00', effectiveDate: '2023-11-22', value: 'R$ 100,00', active: true },
];

export default function SuperAdmin() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setRows(loadrows);
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSave = () => {
    // Lógica para salvar alterações
    handleClose();
  };

  
  const formatDate = (dateString) => {
    const formattedDate = `${dateString.substring(8, 10)}/${dateString.substring(5, 7)}/${dateString.substring(0, 4)}`;
    return formattedDate;
  }

  const formatDateModal = (dateString) => {
    const formattedDate = `${dateString.substring(0, 4)}-${dateString.substring(5, 7)}-${dateString.substring(8, 10)}`;
    return formattedDate;
  }


  return (
    <Grid container spacing={3}>
    <Grid container spacing={3} style={{marginTop:'40px'}}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className={classes.tableHead}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{backgroundColor: '#00AEF3', color: '#FFFFFF'}}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} className={classes.tableCell}>
                      {column.id === 'active' ? (
                        row.active ? <CheckIcon color="primary" /> : <CloseIcon color="secondary" />
                      ) : column.id === 'effectiveDate' && typeof row[column.id] === 'string' ? 
                      (formatDate(row[column.id])
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>

      {/* Modal de Edição */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Psicólogo</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <>
              <TextField
                margin="dense"
                label="Nome"
                fullWidth
                defaultValue={selectedRow.name}
              />
              <TextField
                margin="dense"
                label="CPF"
                fullWidth
                defaultValue={selectedRow.cpf}
              />
              <TextField
                margin="dense"
                label="Data de Entrada"
                fullWidth
                defaultValue={selectedRow.effectiveDate}
              />
              <TextField
                margin="dense"
                label="Valor"
                fullWidth
                defaultValue={selectedRow.value}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    </Grid>
  );
}
