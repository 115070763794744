const dictionary = {
    "en-US": {
        navigation: {
            today: "today",
            previousDay: "Previous day",
            previousWeek: "Previous week",
            previousMonth: "Previous month",
            nextDay: "Next day",
            nextWeek: "Next week",
            nextMonth: "Next month",
            
            next: "next",
            day: "Day",
            week: "Week",
            month: "Month",
            year: "Year",
        },
    },
    "pt-BR": {
        navigation: {
            today: "hoje",
            previousDay: "Dia anterior",
            previousWeek: "Semana anteior",
            previousMonth: "Mês anterior",
            nextDay: "Próximo dia",
            nextWeek: "Próxima semana",
            nextMonth: "Próximo mês",
            previous: "anterior",
            next: "próximo",
            day: "Dia",
            week: "Semana",
            month: "Mês",
            year: "Ano",
        },
    },
}
export default dictionary
