import * as React from 'react';
import { Avatar, Box, Card, Checkbox, Divider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, Grid, IconButton, Tooltip, Modal, Button } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useSelection } from '../../hooks/useSelection';
import CustomerModal from './CustomerModal';

export function CustomersTable({ count = 0, rows = [], onUpdate, onDelete }) {
  const rowIds = React.useMemo(() => rows.map((customer) => customer.id), [rows]);
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const selectedAll = rows.length > 0 && selected?.size === rows.length;
  const [open, setOpen] = React.useState(false);
  const [currentCustomer, setCurrentCustomer] = React.useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const [customerToDelete, setCustomerToDelete] = React.useState(null);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (customer) => {
    setCurrentCustomer(customer);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(customerToDelete.id);
    setOpenDeleteConfirm(false);
  };

  const handleDeleteCancel = () => setOpenDeleteConfirm(false);

  function formatCurrencyBRL(value) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }


  const renderActionRow = ({ row }) => (
    <Box style={{ display: 'flex', gap: '0px' }}>
      <Tooltip title="Editar">
        <IconButton style={{ padding: 6 }} onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Deletar">
        <IconButton style={{ padding: 6 }} color="error" onClick={() => handleDeleteClick(row)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const renderStatusRow = (status) => {
    const color = status === 'Ativo' ? 'green' : 'red';
    return (
      <Grid container justifyContent="center" style={{ backgroundColor: color, padding: 2, borderRadius: 7 }}>
        <Typography variant="subtitle2" style={{ color: '#fff' }}>
          {status}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <Box style={{ overflowX: 'auto' }}>
          <Table style={{ minWidth: '800px' }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAll}
                    indeterminate={selectedSome}
                    onChange={(event) => {
                      if (event.target.checked) {
                        selectAll();
                      } else {
                        deselectAll();
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Data do Cadastro</TableCell>
                <TableCell>Celular</TableCell>
                <TableCell>Valor Cobrado</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const isSelected = selected?.has(row.id);
                return (
                  <TableRow style={{ height: 90 }} hover key={row.id} selected={isSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onChange={(event) => {
                          if (event.target.checked) {
                            selectOne(row.id);
                          } else {
                            deselectOne(row.id);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container style={{ alignItems: 'center', gap: 12 }} direction="row" spacing={2}>
                        <Avatar src={row.avatar} />
                        <Typography variant="subtitle2">{row.name}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>{renderStatusRow(row.status)}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{dayjs(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{row.celular}</TableCell>
                    <TableCell>{formatCurrencyBRL(row.value)}</TableCell>
                    <TableCell>{renderActionRow({ row })}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Divider />
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <Modal open={open} onClose={handleClose} style={{ marginTop: 150 }}>
        <CustomerModal onClose={handleClose} customer={currentCustomer} onUpdate={onUpdate} />
      </Modal>
      <Modal open={openDeleteConfirm} onClose={handleDeleteCancel} style={{ marginTop: 150 }}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            padding: 20,
            boxShadow: 24,
            borderRadius: 10,
          }}
        >
          <Typography variant="h6">Confirmar Deleção</Typography>
          <Typography variant="body1">Você tem certeza que deseja deletar este paciente?</Typography>
          <Box style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={handleDeleteConfirm}>
              Confirmar
            </Button>
            <Button variant="contained" onClick={handleDeleteCancel}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
