import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Grid,
    Typography,
    Container,
    InputAdornment,
    IconButton,
    Link,
    Divider,
    FormControl,
    InputLabel,
    OutlinedInput,
    Paper,
    Box,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import chatImage from "../../assets/logo1.png";
import peopleImage from "../../assets/people.png";
import AnimateButton from "../../components/@extended/AnimateButton";
import useAuth from '../../hooks/useAuth/index.js';

const useStyles = makeStyles((theme) => ({
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: theme.spacing(2),
        width: '100%',
    },
    paperRight: {
        display: "flex",
        backgroundColor: theme.palette.primary.main,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: theme.spacing(2),
        width: '100%',
    },
    peopleImage: {
        borderRadius: theme.spacing(2),
        height: "60%",
        width: "70%",
        border: '1px solid white',
    },
    form: {
        width: "100%",
    },
    submit: {
        margin: theme.spacing(4, 0, 2),
    },
    containerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
        },
    },
    mobileContainer: {
        flex: 1,
        display: 'flex',
        maxWidth: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    RegisterContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.paper,
        boxShadow: "2px 0px 15px 2px rgba(0, 0, 0, 0.16)",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    hideOnMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const Register = () => {
    const classes = useStyles();
    const [user, setUser] = useState({ name: "", email: "", celular: "", password: "", confirmPassword: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register } = useAuth();
    const navigate = useNavigate();
    const [passwordError, setPasswordError] = useState(false);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (user.password !== user.confirmPassword) {
            setPasswordError(true);
            return;
        }
        try {
            await register(user);
            navigate("/");
        } catch (error) {
            console.error("Registration Error:", error);
        }
    };

    return (
        <div >
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.containerWrapper}>
                    <Container component="div" maxWidth="md" className={classes.mobileContainer}>
                        <Paper className={classes.RegisterContainer}>
                            <Box width="100%">
                                <div className={classes.paperLeft}>
                                    <h4 style={{ fontSize: 28 }}>Criar Conta</h4>
                                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                        <Grid container >
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="name"
                                                    label="Nome"
                                                    name="name"
                                                    value={user.name}
                                                    onChange={handleChangeInput}
                                                    autoComplete="name"
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Email"
                                                        helperText="exemplo@gmail.com"
                                                        name="email"
                                                        value={user.email}
                                                        onChange={handleChangeInput}
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="celular"
                                                        label="Celular"
                                                        name="celular"
                                                        value={user.celular}
                                                        onChange={handleChangeInput}
                                                        autoComplete="tel"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl required fullWidth variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={user.password}
                                                        onChange={handleChangeInput}
                                                        name="password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={70}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6}>
                                                <FormControl required fullWidth variant="outlined" error={passwordError}>
                                                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirmar Senha</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-confirm-password"
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        value={user.confirmPassword}
                                                        onChange={handleChangeInput}
                                                        name="confirmPassword"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle confirm password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={140}
                                                    />
                                                    {passwordError && (
                                                        <Typography variant="body2" color="error">
                                                            As senhas não coincidem
                                                        </Typography>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            </Grid>

                                        </Grid>
                                        <AnimateButton>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Registrar
                                            </Button>
                                        </AnimateButton>

                                        <Grid container justifyContent="flex-end">
                                            <Grid item>
                                                <Link
                                                    variant="body2"
                                                    component={RouterLink}
                                                    to="/"
                                                >
                                                    Já tem uma conta? Faça Login
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Box>
                        </Paper>
                    </Container>
                </div>
            </Container>
        </div>
    );
};

export default Register;