import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';

const barChartOptions = {
  chart: {
    height: 350,
    type: 'bar'
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded'
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  fill: {
    opacity: 1
  }
};

export default function MonthlyBarChart({ data }) {
  const theme = useTheme();
  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [series, setSeries] = useState([
    {
      data: Array(7).fill(0) // Inicia a série com 7 valores zerados, um para cada dia da semana
    }
  ]);

  const [options, setOptions] = useState(barChartOptions);

  useEffect(() => {

    const totalConsultationsByWeek = Array(7).fill(0); // Array para armazenar o total de consultas por dia da semana
    const consultationsThisWeek = []; // Array para armazenar as consultas feitas na semana atual
  
    const currentWeek = getCurrentWeekNumber(); 
  
    data.forEach((appointment) => {
      const { appointmentDate } = appointment;
      const date = new Date(appointmentDate);
      const weekNumber = getWeekNumber(date); 
  

      if (weekNumber === currentWeek) {
        const adjustedDayOfWeek = getAdjustedDayOfWeek(date); // Ajusta o dia da semana (segunda-feira como 0)
  
        // Incrementa o total de consultas no dia correspondente
        totalConsultationsByWeek[adjustedDayOfWeek] += 1;
        consultationsThisWeek.push(appointment);
      }
    });

    if (consultationsThisWeek && consultationsThisWeek.length > 0) {
      // Aqui estamos mapeando o array de consultas por dia da semana (0 - segunda, 6 - domingo)
      const consultationsByDay = Array(7).fill(0); // Array para armazenar o número de consultas por dia da semana

      consultationsThisWeek.forEach(({ appointmentDate }) => {
        const date = new Date(appointmentDate);
        const dayOfWeek = date.getDay(); // Obtem o dia da semana (0 é domingo, 1 é segunda, etc.)

        // Incrementa o contador de consultas no respectivo dia (ajustando para segunda como 0)
        consultationsByDay[dayOfWeek === 0 ? 6 : dayOfWeek - 1] += 1;
      });

      setSeries([
        {
          data: consultationsByDay // Atualiza os dados da série com as consultas organizadas por dia da semana
        }
      ]);
    }
  }, [data]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [info],
      xaxis: {
        categories: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'], // Dias da semana
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      }
    }));
  }, [primary, info, secondary]);

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };
  
  const getCurrentWeekNumber = () => {
    const today = new Date();
    return getWeekNumber(today);
  };
  
  const getAdjustedDayOfWeek = (date) => {
    const day = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
    return day === 0 ? 6 : day - 1; // Adjust so Monday is 0, Sunday is 6
  };
  

  return (
    <Box id="chart" sx={{ bgcolor: 'transparent' }}>
      <ReactApexChart options={options} series={series} type="bar" height={365} />
    </Box>
  );
}

MonthlyBarChart.propTypes = {
  data: PropTypes.array.isRequired // Espera que 'data' seja uma array de consultas com datas
};
