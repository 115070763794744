import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  }
};

const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getCurrentWeekNumber = () => {
  const today = new Date();
  return getWeekNumber(today);
};

const getAdjustedDayOfWeek = (date) => {
  const day = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  return day === 0 ? 6 : day - 1; // Adjust so Monday is 0, Sunday is 6
};

// ==============================|| INCOME AREA CHART ||============================== //

export default function IncomeAreaChart({ slot, data }) {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.secondary.main],
      xaxis: {
        categories:
          slot === 'month'
            ? ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec']
            : ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: slot === 'month' ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      }
    }));
  }, [primary, secondary, line, theme, slot]);

  const [series, setSeries] = useState([
    {
      name: 'Pagos',
      data: [0, 86, 28, 115, 48, 210, 136]
    },
    {
      name: 'Não Pagos',
      data: [0, 43, 14, 56, 24, 105, 68]
    }
  ]);

  useEffect(() => {
    const paidData = [];
    const unpaidData = [];

    if (slot === 'month') {
      // Initialize arrays with zeros for each month
      const paidByMonth = Array(12).fill(0);
      const unpaidByMonth = Array(12).fill(0);

      data.forEach(({ appointmentDate, paid }) => {
        const date = new Date(appointmentDate);
        const month = date.getMonth(); // 0 (Jan) to 11 (Dec)

        if (paid) {
          paidByMonth[month] += 1;
        } else {
          unpaidByMonth[month] += 1;
        }
      });

      paidData.push(...paidByMonth);
      unpaidData.push(...unpaidByMonth);
    } else if (slot === 'week') {
      const paidByWeek = Array(7).fill(0);
      const unpaidByWeek = Array(7).fill(0);

      const currentWeek = getCurrentWeekNumber();

      data.forEach(({ appointmentDate, paid }) => {
        const date = new Date(appointmentDate);
        const weekNumber = getWeekNumber(date);

        if (weekNumber === currentWeek) {
          const adjustedDayOfWeek = getAdjustedDayOfWeek(date); // Adjust the day of week

          if (paid) {
            paidByWeek[adjustedDayOfWeek] += 1;
          } else {
            unpaidByWeek[adjustedDayOfWeek] += 1;
          }
        }
      });

      paidData.push(...paidByWeek);
      unpaidData.push(...unpaidByWeek);
    }

    setSeries([
      {
        name: 'Pagos',
        data: paidData
      },
      {
        name: 'Não Pagos',
        data: unpaidData
      }
    ]);
  }, [slot, data]);

  return <ReactApexChart options={options} series={series} type="area" height={422} />;
}

IncomeAreaChart.propTypes = { slot: PropTypes.string };
