import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useNotifications from '../../hooks/useNotifications/index'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationContext } from '../../contexts/NotificationsContext'


export default function NotificationForm() {
  const [open, setOpen] = useState(false);
  const { createNotification } = useNotifications()
  const {newNotification, setNewNotification} = useNotificationContext()
  const [notification, setNotification] = useState({title:"" , content:""})

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    setNotification({ ...notification, [e.target.id]: e.target.value });
  };





  const handleSubmit = async(e) =>{
    e.preventDefault();

    try{
      const notificationData = await createNotification(notification)
      if(notificationData){
        setNewNotification(!newNotification);
        toast.success(
          <strong>Notificação criada com sucesso</strong>,
          {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      })
    }else{
      toast.error(
        <div>
          <strong>Erro ao criar Notificação</strong>
          <div>Preencha "Titulo" e "Conteúdo"</div>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });      
    }
        
    }catch(err){
      toast.error(
          <strong>Erro interno: {err}</strong>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    };
  };


  return (
    <div>
      <div style={{display:'flex', justifyContent:'flex-end', marginBottom:'10px'}}>
        <Button 
        color='primary' 
        variant="contained"   
        onClick={handleClickOpen}
        >
          Nova Notificação
        </Button>
      </div>
        <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <center><DialogTitle id="form-dialog-title">Nova notificação</DialogTitle></center>
          <DialogContent >
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Titulo da Notificação"
              type="text"
              variant='outlined'
              defaultValue="New Feature"
              onChange={handleChangeInput}
              value = {notification.title}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="content"
              label="Conteúdo da Notificação"
              type="text"
              variant='outlined'
              onChange={handleChangeInput}
              value = {notification.content}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button 
            onClick={handleClose} 
            color="primary" 
            variant='contained'
            >
              Cancelar
            </Button>
            <Button 
            onClick={handleSubmit}
            variant='contained'
            color="primary"
            >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
