import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuthState] = useState(null);

  const setAuth = (authData) => {
    setAuthState(authData); 

    if (authData) {
      localStorage.setItem('auth', JSON.stringify(authData)); 
    }
  };

  useEffect(() => {
    const savedAuth = localStorage.getItem('auth');
    if (savedAuth) {
      setAuthState(JSON.parse(savedAuth)); 
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
