import React, { useContext, useState, useMemo, useRef, useEffect } from "react"
import { CalendarContext } from "../../util/CalendarContext"
import { makeStyles, Theme } from "@material-ui/core/styles"
import clsx from "clsx"
import Slide from "@material-ui/core/Slide"
// import red from "@material-ui/core/colors/red"
import { Alert, AlertTitle } from '@material-ui/lab';
import grey from "@material-ui/core/colors/grey"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import CloseIcon from "@material-ui/icons/Close"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import SubjectIcon from "@material-ui/icons/Subject"
import { format, getTime } from "date-fns"
import TimeSelect from "../../components/@extended/TimeSelect"
import Datepicker from "../../components/@extended/Datepicker"
import { Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@material-ui/core"
import ToggleButtonsMultiple from "../../components/@extended/ToogleButton"
import AddPeopleEvent from "./AddPeopleEvent"

// maxWidth: xs, sm, md, lg, xl

const Transition = (function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
    divTitleButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    fullScreenButton: {
        color: theme.palette.grey[900],
    },
    sessionDialog: {
        "& .MuiDialog-paper": {
            height: "75vh",
            width: "75vw",

        }
    },
    cancelButton: {
        // color: red[500],
        // "&:hover": {
        //     backgroundColor: red[100],
        // },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        // marginTop: -30,
        // width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        // minWidth: 120,
    },
    formControlFlex: {
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    title: {
        marginTop: 0,
    },
    inputLabel: {
        fontWeight:500,
            fontSize: 15,
            color: grey[600]
    },
    descriptionIcon: {
        // marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    betweenDates: {
        textAlign: "center",
        fontSize: 16,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    radioScheduler:{
        "& .MuiTypography-body1":{
            fontWeight:500,
            fontSize: 15,
            color: grey[600]
        }

    },
    datepicker: {
        "&:hover": {
            backgroundColor: grey[100],
        },
    },
    dayOfWeek: {
        marginLeft: theme.spacing(1),
        color: grey[500],
    },
}))

// const firstTime = "8:00"
const interval = 30
const timeOptions = Array.from(Array(24).keys()).reduce((time, hour) => {
    Array.from(Array(60 / interval).keys()).map(i => {
        const timeItem = (+(hour + "." + i * interval)).toFixed(2).replace(".", ":")
        time.push({ value: timeItem, label: timeItem })
        return null
    })
    return time
}, [])

function CalendarEventDialog(props) {
    const classes = useStyles()

    // const { stateCalendar, setStateCalendar } = useContext(CalendarContext)
    const { stateCalendar, setStateCalendar } = useContext(CalendarContext)
    const {
        modal = false,
        eventDialogMaxWidth = "sm",
        fullscreen = false,
        allowFullScreen = false,
        withCloseIcon = true,
        eventID = 0,
        title,
        description,
        openDialog,
        eventBeginDate,
        eventBeginTime,
        eventEndDate,
        eventEndTime,
        // minutes,
        locale,
    } = stateCalendar
    const handleCloseDialog = () => {
        setStateCalendar({ ...stateCalendar, openDialog: false, openViewDialog: false })
    }

    const [fullScreen, setFullScreen] = useState(false)

    // useEffect(() => {
    //     console.log("useEffect for eventBeginDate", eventBeginDate)
    //     console.log("useEffect for eventEndDate", eventEndDate)
    // }, [eventBeginDate, eventEndDate])

    // useEffect(() => {
    //     console.log("useEffect for eventBeginDate", eventBeginDate)
    //     console.log("useEffect for eventEndDate", eventEndDate)
    // }, [eventBeginDate, eventEndDate])

    const textFieldTitle = useRef(null)
    const [titleTF, setTitleTF] = useState(title)
    const [descriptionTF, setDescriptionTF] = useState(description)

    useEffect(() => {
        setTitleTF(title)
    }, [title])

    useEffect(() => {
        setDescriptionTF(description)
    }, [description])

    return useMemo(() => {

        const onExited = () => {
            setFullScreen(false)
            setDescriptionTF("")
            setTitleTF("")
        }

        const handleOnClose = () => {
            !modal && handleClose()
        }

        const handleClose = () => {
            handleCloseDialog()
        }

        const handleFullScreen = () => {
            setFullScreen(!fullScreen)
        }

        const handleOk = () => {
            const localStorageMarckers = window.localStorage.getItem("markers")
            console.group("Handle Event");
            const begin = formatDateTime(eventBeginDate, eventBeginTime.value);
            const end = new Date(begin)
            end.setHours(end.getHours() + 1);
            const markers = (localStorageMarckers && JSON.parse(localStorageMarckers)) || []
            const marker = {
                id: eventID > 0 ? eventID : getTime(new Date()),
                title: titleTF,
                begin,
                end,
                description: descriptionTF,
            }
            console.log("marker",marker)

            console.log({ eventID })
            window.localStorage.setItem(
                "markers",
                JSON.stringify([...markers.filter((markEvent) => markEvent.id !== eventID), marker]),
            )

            handleClose()
        }

        // function handleCancel() {
        //     handleClose()
        // }

        const formatDateTime = (newDate, newTime) => {

        
            // Verificar se newDate é um objeto Date
            if (!(newDate instanceof Date) || isNaN(newDate.getTime())) {
                console.error("Data inválida.");
                return null;
            }
        
            // Dividir newTime em horas e minutos
            const [hours, minutes] = newTime.split(':');
            if (!hours || !minutes) {
                console.error("Formato de hora inválido. Use HH:MM.");
                return null;
            }
        
            // Clonar a data original para evitar mutações
            const combinedDateTime = new Date(newDate);
        
            // Ajustar as horas e minutos na data clonada
            combinedDateTime.setHours(Number(hours));
            combinedDateTime.setMinutes(Number(minutes));
            combinedDateTime.setSeconds(0);
            combinedDateTime.setMilliseconds(0);
        
            console.log("Formatted Date", combinedDateTime);
            return combinedDateTime;
        }
        
        
        
        const onChangeBeginDate = (newDate) => {
            setStateCalendar({
                ...stateCalendar,
                eventBeginDate: newDate,
                eventEndDate: new Date(newDate)
            })
        }
        const onChangeEndDate = (newDate) => {
            setStateCalendar({ ...stateCalendar, eventEndDate: newDate })
        }

        const onChangeBeginTime = (newValue) => {
            setStateCalendar({ ...stateCalendar, eventBeginTime: newValue })
        }

        const onChangeEndTime = (newValue) => {
            setStateCalendar({ ...stateCalendar, eventEndTime: newValue })
        }

        const dateFormat = "dd/MM/yyyy"

        // const buttonDisabled = titleTF.length <= 0 || eventBeginDate > eventEndDate
        const buttonDisabled =false;

        return (
            <Dialog
                onEntered={() => {
                    setTitleTF(title)
                    setDescriptionTF(description)
                    textFieldTitle.current !== null && textFieldTitle.current?.focus()
                }}
                onExited={onExited}
                className={classes.sessionDialog}
                fullScreen={fullscreen || fullScreen}
                fullWidth={true}
                maxWidth="sm"
                open={openDialog}
                onClose={handleOnClose}
                aria-labelledby='max-width-dialog-title'
                // TransitionComponent={Transition}
                keepMounted={false}
            >
                <DialogTitle>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>Nova Sessão</Typography>
                    <div className={classes.divTitleButton}>
                        {allowFullScreen ? (
                            <IconButton
                                aria-label='Close'
                                className={classes.fullScreenButton}
                                onClick={handleFullScreen}
                            >
                                {!fullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
                            </IconButton>
                        ) : null}
                        {withCloseIcon ? (
                            <IconButton aria-label='Close' className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        {/* <Grid>
                            <ToggleButtonsMultiple></ToggleButtonsMultiple>
                        </Grid> */}
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                <FormControlLabel
                                    value="top"
                                    className={classes.radioScheduler}
                                    control={<Radio color="primary" />}
                                    label="Semanal"
                                />
                                <FormControlLabel
                                    value="start"
                                    className={classes.radioScheduler}
                                    control={<Radio color="primary" />}
                                    label="Quinzenal"
                                />
                                <FormControlLabel
                                    value="bottom"
                                    className={classes.radioScheduler}
                                    control={<Radio color="primary" />}
                                    label="Mensal"
                                />
                                <FormControlLabel 
                                value="end"
                                 control={<Radio color="primary" />} 
                                 className={classes.radioScheduler}
                                 label="Única Consulta" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl className={clsx(classes.formControl, classes.formControlFlex)}>
                            <Grid container spacing={3} justifyContent="start" style={{ width: '80%' }} >
                                <Grid item xs={6} >
                                    <Typography variant="subtitle2" className={classes.inputLabel}>Selecione a data</Typography>
                                    <Datepicker
                                        styleCls={classes.datepicker}
                                        dateFormat={dateFormat}
                                        originalValue={new Date(eventBeginDate)}
                                        onChange={onChangeBeginDate}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="subtitle2" className={classes.inputLabel}>Horário</Typography>
                                    <TimeSelect
                                        placeholder={""}
                                        options={timeOptions}
                                        originalValue={{
                                            value: eventBeginTime.value,
                                            label: eventBeginTime.label,
                                        }}
                                        onChange={onChangeBeginTime}
                                    />
                                </Grid>
                            </Grid>


                        </FormControl>
                        <FormControl className={clsx(classes.formControl)}>
                            <Grid justifyContent="start"   >
                                <Typography variant="subtitle2" className={classes.inputLabel}>Adicionar Paciente</Typography>
                                <AddPeopleEvent></AddPeopleEvent>
                            </Grid>


                        </FormControl>
                        <FormControl className={clsx(classes.formControl)}>
                        <Typography variant="subtitle2" className={classes.inputLabel}>Observações</Typography>

                        <TextField
                                multiline
                                minRows={1}
                                variant="outlined"
                                />


                        </FormControl>
              

                    </form>

                </DialogContent>
                <DialogActions style={{ flexDirection: 'column', alignItems: 'start' }} >
                    <Grid style={{ marginBottom: 15, marginLeft: 8 }}>
                        <Alert severity="info">Todas Sessões tem duração de 1 hora</Alert>

                    </Grid>
                    <Button variant="contained" style={{ width: '100%' }} onClick={handleOk} color='primary' disabled={buttonDisabled}>
                        Salvar
                    </Button>

                </DialogActions>
            </Dialog>
        )
        // ....
        // ....
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        openDialog,
        titleTF,
        descriptionTF,
        eventBeginDate,
        eventBeginTime,
        eventEndDate,
        eventEndTime,
        allowFullScreen,
        classes,
        eventDialogMaxWidth,
        eventID,
        fullScreen,
        fullscreen,
        handleCloseDialog,
        locale,
        modal,
        title,
        description,
        withCloseIcon,
    ])
}

export default CalendarEventDialog
