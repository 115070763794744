// material-ui
import React, { useState, useContext, useEffect } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// project import
import MainCard from '../../components/MainCard';
import AnalyticEcommerce from '../../components/AnalyticEcommerce';
import MonthlyBarChart from './MonthlyBarChart';
// import UniqueVisitorCard from './UniqueVisitorCard';
// import SaleReportCard from './SaleReportCard';
// import OrdersTable from './OrdersTable';

import { makeStyles, useTheme } from '@material-ui/core';
import OrdersTable from './OrdersTable';
import UniqueVisitorCard from './UniqueVisitorCard';
import ReportAreaChart from './ReportAreaChart';
import useAppointment from '../../hooks/useAppointment/index.js';
import { useAuthContext } from '../../contexts/UserContext.js';
import useCustomer from '../../hooks/useCustomer/index.js';

// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const useStyles = makeStyles((theme) => ({
  dataTitle: {
    fontWeight: 600
  }
}));
export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const { auth, setAuth } = useAuthContext();
  console.log("auth ",auth);
  const { get } = useAppointment();
  const [appointments, setAppointments] = useState([]);
  const [ customers, setCustomers ] = useState([]);
  const {search} = useCustomer();
  const [totalConsultationsByWeek, setTotalConsultationsByWeek] = useState([]);
  const [growth, setGrowth] = useState(0);

  React.useEffect(() => {
    getAppointments();
    getCustomers();
  }, []);

  useEffect(() => {
    const totalConsultationsByWeek = Array(7).fill(0); // Array para armazenar o total de consultas por dia da semana
    const consultationsThisWeek = []; // Array para armazenar as consultas feitas na semana atual
  
    const currentWeek = getCurrentWeekNumber(); 
    
    appointments.forEach((appointment) => {
      const { appointmentDate } = appointment;
      const date = new Date(appointmentDate);
      const weekNumber = getWeekNumber(date); 
  

      if (weekNumber === currentWeek) {
        const adjustedDayOfWeek = getAdjustedDayOfWeek(date); // Ajusta o dia da semana (segunda-feira como 0)
  
        // Incrementa o total de consultas no dia correspondente
        totalConsultationsByWeek[adjustedDayOfWeek] += 1;
        consultationsThisWeek.push(appointment);
      }
    });
    setTotalConsultationsByWeek(consultationsThisWeek);
    GrowthRate(appointments);

  }, [appointments]);
  
  


  const getAppointments = async () => {
    console.log(auth);
    try {
      const response = await get(auth.id);
      setAppointments(response);
      console.log(response);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await search(auth.id);
      setCustomers(response);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }

  function calcBiling(){
    let total = 0;
    appointments.forEach(appointment => {
      if(appointment.paid){
        total += appointment.value;
      }
    });
    return total;
  }


  function daysOnPlatform(createdAt) {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();

    const diffInMilliseconds = currentDate - createdDate;

    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays;
  }

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };
  
  const getCurrentWeekNumber = () => {
    const today = new Date();
    return getWeekNumber(today);
  };
  
  const getAdjustedDayOfWeek = (date) => {
    const day = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
    return day === 0 ? 6 : day - 1; // Adjust so Monday is 0, Sunday is 6
  };

  function getLastTwoMonths() {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Primeiro dia do mês anterior
    const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1); // Primeiro dia de dois meses atrás
  
    return {
      currentMonthStart: new Date(today.getFullYear(), today.getMonth(), 1), // Primeiro dia do mês atual
      lastMonthStart: lastMonth,
      twoMonthsAgoStart: twoMonthsAgo
    };
  }

  function GrowthRate(appointments) {
    if (appointments && appointments.length > 0) {
      const { lastMonthStart, twoMonthsAgoStart, currentMonthStart } = getLastTwoMonths();

      let valueTwoMonthsAgo = 0;
      let valueLastMonth = 0;

      appointments.forEach(({ appointmentDate, value, paid }) => {
        const date = new Date(appointmentDate);

        if (date >= twoMonthsAgoStart && date < lastMonthStart && paid) {
          valueTwoMonthsAgo += value;
        }

        if (date >= lastMonthStart && date < currentMonthStart && paid) {
          valueLastMonth += value;
        }
      });      

      // Calcular o crescimento percentual
      if (valueTwoMonthsAgo > 0) {
        const growthRate = ((valueLastMonth - valueTwoMonthsAgo) / valueTwoMonthsAgo) * 100;
        setGrowth(growthRate.toFixed(2));
      } else {
        setGrowth(0); 
      }
    }
  }


  return (
    <Grid container spacing={3} >
      <Grid item xs={12} >
        <Typography variant="subtitle1" className={classes.dataTitle} >Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Consultas Realizadas" count={appointments.length} percentage={59.3} extra="35,000"  />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Tempo na Plataforma (Dias)" count={daysOnPlatform(auth.createdAt)} percentage={70.5} extra="8,900" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total de Pacientes" count={customers.length}percentage={27.4} isLoss color="warning" extra="1,943" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Faturamento" count={calcBiling()} percentage={27.4} isLoss color="warning" extra="$20,395" />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <UniqueVisitorCard data={appointments}/>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item style={{ marginBottom: 4 }}>
            <Typography variant="subtitle1" className={classes.dataTitle} >Consultas Realizadas</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard style={{ marginTop: 35 }} content={false}>
          <Box style={{ padding: 5, paddingBottom: 0 }} >
            <Container spacing={2}>
              <Typography variant="h6" style={{ color: theme.palette.cardTitle, fontWeight: 400, fontSize: '0.875rem' }} >
                Dados Semanais
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 600 }} color="inherit">
                {totalConsultationsByWeek.length}
              </Typography>
            </Container>
          </Box>
          <MonthlyBarChart data={appointments}/>
        </MainCard>
      </Grid>



      {/* row 3 */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item style={{ marginBottom: 4 }}>
            <Typography variant="subtitle1" className={classes.dataTitle} >Atualizações</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard  content={false}>
          <OrdersTable />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item style={{ marginBottom: 4 }}>
            <Typography variant="subtitle1" className={classes.dataTitle} >Análise de Desempenho</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard content={false}>
          <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>
          <ListItem divider>
            <ListItemText primary="Crescimento da Empresa" />
            <Typography variant="h5">{growth > 0 ? `+${growth}%` : `-${growth}%`}</Typography>
          </ListItem>
          </List>
          <ReportAreaChart data={appointments} />
        </MainCard>
      </Grid>

      {/* row 4 */}


    </Grid>
  );
}
