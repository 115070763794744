import api from "../../api";

const useNotifications =  () => {
    const createNotification = async (notificationData) =>{
        try {
            const response = await api.post("/notifications/createNotification", notificationData);
            
            if (response.status === 200) {  
              const data = response.data;
              return data;  
            } else {
              console.error("Login failed");
              return null; 
            }
          } catch (err) {
            console.error("Erro durante a criação da notificação:", err);
            return null;  
          }
    };

    const getNotifications = async () => {
      try{
        const response = await api.get("/notifications");
        
        const data = response.data;
        return data;
      }catch(err){
        console.log("Erro ao buscar notificações",err);
        return null
      }
    }

    const deleteNotifications = async(id) => {
        try{
          const response = await api.delete("/notifications/" + id)
            if(response.status === 200){
              const data = response.data;
              return data;
              
            }
        }catch(err){
          return null;
        }
    };

    const patchNotifications = async(id, title, content, userId) => {
        try{
          const response = await api.patch("/notifications/patch/" + id, {title, content, userId})
          console.log(response);
            if(response.status === 200){
              const data = response.data;
              return data;
              
            }
        }catch(err){
          return null;
        }
    };

    return{
      createNotification,
      deleteNotifications,
      getNotifications,
      patchNotifications,
      
    }
}

export default useNotifications;