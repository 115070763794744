
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import Typography from '@material-ui/core/Typography';

import { Button, Grid, IconButton, Link, TableContainer, TextField, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const useStyles = makeStyles((theme) => ({
    statusRow: {
    }
}));

const initialTasks = [
    {
        id: 1,
        name: "Conferir  Funcionário"
    },
    {
        id: 2,
        name: "Atualizar Planilha"
    }
]
export function TasksManagement() {

    const [tasks, setTasks] = useState(initialTasks);
    const [checkedTasks, setCheckedTasks] = useState({});
    const [isAddingTask, setIsAddingTask] = useState(false);
    const [newTaskName, setNewTaskName] = useState('');
    const theme = useTheme();

    const handleDeleteTask = (taskId) => {
        setTasks((prevTasks) => prevTasks.filter(task => task.id !== taskId));
    };
    const handleCheckboxChange = (taskId) => {
        setCheckedTasks((prev) => ({
            ...prev,
            [taskId]: !prev[taskId],
        }));
    };
    const handleTaskClick = () => {
        if (isAddingTask) {
            setNewTaskName('');
            setIsAddingTask(false);
        } else {
            setIsAddingTask(true);
        }

    };

    const handleNewTaskNameChange = (event) => {
        setNewTaskName(event.target.value);
    };
    const handleAddTaskSubmit = (event) => {
        if (event.key === 'Enter') {
            if (newTaskName.trim()) {
                const newTask = {
                    id: Date.now(), // Unique ID for the new task
                    name: newTaskName.trim()
                };
                setTasks((prevTasks) => [...prevTasks, newTask]);

            }
        }
    };

    return (
        <Card style={{ borderRadius: 7, height: '100%' }}>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'  style={{ padding:7, marginBottom: 7 }}  >
                <Box style={{display:'flex', alignItems:'center', gap:7}}  alignItems='center' item>
                    <FormatListBulletedIcon />
                    <Typography variant="subtitle1" style={{ fontWeight: 600 }}>Gestão de Tarefas</Typography>

                </Box>
                <Link style={{ cursor: 'pointer' }} onClick={handleTaskClick} >{isAddingTask ? "Cancelar" : "Adicionar Tarefa"}</Link>


            </Grid>
            {isAddingTask && (
                <Box style={{ height: "18%" }}>
                    <Divider style={{ marginBottom: 12 }} />
                    <Grid item container justifyContent='center' alignContent='center' alignItems='center' >
                        <TextField
                            size="small"
                            variant='outlined'
                            fullWidth
                            style={{ width: "90%" }}
                            label="Nome da Tarefa"
                            value={newTaskName}
                            onChange={handleNewTaskNameChange}
                            onKeyDown={handleAddTaskSubmit}
                            autoFocus
                        />
                    </Grid>
                </Box>
            )}
            <Grid container direction='column'>

                {tasks.map((task) => (
                    <>
                        <Divider />
                        <Grid container justifyContent='space-between' alignItems='center' key={task.id}>
                            <Tooltip title="Concluir Tarefa">
                                <Checkbox
                                    checked={checkedTasks[task.id] || false}
                                    onChange={() => handleCheckboxChange(task.id)}
                                />
                            </Tooltip>

                            <Typography
                                style={{
                                    fontSize: 14,
                                    textDecoration: checkedTasks[task.id] ? 'line-through' : 'none'
                                }}
                            >
                                {task.name}
                            </Typography>

                            <IconButton color="primary" aria-label="delete task" component="span" onClick={() => handleDeleteTask(task.id)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Grid>

                    </>
                ))}




            </Grid>




        </Card>
    );
}
