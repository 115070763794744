import api from "../../api";

const useAuth = () => {

  const login = async (userData) => {
    try {
      const response = await api.post("/auth", userData);
      
      if (response.status === 200) {  
        const data = response.data;
        localStorage.setItem("authToken", data.token); 
        return data;  
      } else {
        console.error("Login failed");
        return null; 
      }
    } catch (err) {
      console.error("Erro durante o login:", err);
      return null;  
    }
  };

  const register = async (userData) => {
    try {
      const { data } = await api.post("/register", userData);
      return data;
    } catch (err) {
      console.error("Erro durante o registro:", err);
    }
  };

  const logout = async () => {
    try {
      await api.post("/logout");
    } catch (err) {
      console.error("Erro durante o logout:", err);
    }
  };

  const editUser = async (userData) => {
    try {
      const { data } = await api.put("/editUser", userData);
      return data;
    } catch (err) {
      console.error("Erro durante o registro:", err);
    }
  };

  return {
    login,
    register,
    logout,
    editUser
  };
};

export default useAuth;
