import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import Register from './pages/register'; 
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoggedLayout from './layout';
import Dashboard from './pages/dashboard';
import Customers from './pages/customers';
import Calendar from './pages/calendar';
import Configuration from './pages/configuration';
import Clinic from './pages/clinic';
import Financial from './pages/financial';
import SuperAdmin from './pages/superAdmin';
import { PrivateRoute } from './pages/Private Route';
import { AuthProvider } from './contexts/UserContext'
import { NotificationProvider } from './contexts/NotificationsContext.js'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const theme = createTheme(
    {
      palette: {
        type: "light",
        primary: { main: "#00AEF3" },
        textPrimary: "#FFFFFF",
        borderPrimary: "#174873",
        light: "#1FA634",
        colorHeader: "#09233b",
        textSecondary: "#667085",
        sideNav: "#09233b",
        textGray: "#e6ebf1",
        cardBorder: "#dce0e6",
        cardTitle: "#8c8c8c",
        icons: "#F28D35",
        paper: "#F2F2F2",
        danger: "#a83232",
        textWhite : "#F2F2F2"
      },
      typography: {
        fontFamily: [
          'Inter',
        ].join(','),
      },
      overrides: {
        MuiButton: {
          contained: {
            color: 'white !important',
          },
        },

      },
    },
  );
  return (
    <NotificationProvider>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} /> 
          <Route element={<PrivateRoute><LoggedLayout /></PrivateRoute>}>
            <Route path="/dashboard" element={<Dashboard />} /> 
            <Route path="/clinica" element={<Clinic />} />
            <Route path="/clientes" element={<Customers />} />
            <Route path="/agenda" element={<Calendar />} />
            <Route path="/configuracoes" element={<Configuration />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/superAdmin" element={<SuperAdmin />} />
          </Route>
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </AuthProvider>
    </NotificationProvider>
  );
}

export default App;
