import React, { useState, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import NotificationsIcon from '@material-ui/icons/NotificationsNone';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MessageIcon from '@material-ui/icons/Message';
import CloseIcon from '@material-ui/icons/Close';

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MainListItems from "./MainListItems";

import logo from "../assets/logoSemFundo2.png";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from '../hooks/useAuth/index.js';
import { useAuthContext } from '../contexts/UserContext.js'
import useNotifications from "../hooks/useNotifications/index.js";


const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    backgroundColor: theme.palette.fancyBackground,
    '& .MuiButton-outlinedPrimary': {
      color: theme.mode === 'light' ? '#0678F9' : '#FFF',
      border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.mode === 'light' ? theme.palette.primary.main : '#FFF',
    }
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    color: "white",
    background: theme.palette.colorHeader,
    display:"flex",
    justifyContent:"space-between",
    height: '70px',
  },

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "100px",
    background: theme.palette.barraSuperior,
    [theme.breakpoints.down("sm")]: {
      height: "48px"
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    justifyContent: "end",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color:"white"
  },
  menuButtonHidden: {
    display: "none",
  },
  divider:{
    background: theme.mode === 'light' ? theme.palette.primary : '',
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.sideNav,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: 35,
    ...theme.scrollbarStyles,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  onlineIndicator :{
    width: '10px',
    height: '10px',
    background: '#08bd41',
    borderRadius: '50%', /* Torna a div redonda */
  },
  logoArea:{
    display: 'flex',
    marginRight:22,
    gap:9
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  containerWithScroll: {
    marginTop: 25,
    flex: 1,
    padding: theme.spacing(1),
    background: theme.mode === 'light' ? theme.palette.primary.main : '',
    
    color: theme.mode === 'light' ? '#ffff' : '',
    ...theme.scrollbarStyles,
    '& div':{
      display: 'flex',
      flexDirection: 'column',
    }
  },
  alignProfileIcon : {
    justifyContent:"end"
  },
  buttonStyle : {
    color: theme.palette.paper,
  }
}));

const LoggedLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuNotificationsOpen, setMenuNotificationsOpen] = useState(false);
  const [drawerOpen, setDrawerOpen]= useState(() => {
    if (document.body.offsetWidth < 600) {
      return false
    } else{
      return true
    };
  });
  const navigation= useNavigate();
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const theme = useTheme();
  const {logout} = useAuth();
  const { auth, setAuth } = useAuthContext();
  const { getNotifications, patchNotifications } = useNotifications();
  const [notifications, setnotifications] = useState([]);

  useEffect(() => {
    fetchNotifications(); 
  }, [auth]);

  const fetchNotifications = async () =>{
    try {
      const data = await getNotifications();
      const unreadNotifications = data.filter(
        (notification) => !notification.UsersRead.includes(auth.id)
      ); 
      setnotifications(unreadNotifications); 
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  };




  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleDelete = async(id) =>{
    try{
      const data = await patchNotifications(id, null, null, auth.id);
      if(data){
        fetchNotifications();
      }
    }catch(err){
      console.log("Erro na rota patch");
    }
    
    
  };



  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
    setMenuNotificationsOpen(true);
  };

  const handleCloseMenuNotifications = () => {
    setAnchorElNotifications(null);
    setMenuNotificationsOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = async () => {
    try {
      await logout(); 
      setAuth(null);

      handleCloseMenu(); 
      navigation("/"); 
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  const formatDate = (notificationDate) => {
    const date = new Date(notificationDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  const newNotifications = `Você tem ${notifications.length} novas notificações`;
  const noNotifications = "Você não possui notificações";

  

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <div className={classes.logoArea}>
            <img src={logo} style={{ height: "72px"}} alt="logo" />
            <div style={{marginTop:18}}>
                <Typography variant="h6" style={{color: "#F2F2F2",fontWeight:900}}>PsicoFácil</Typography>                
            </div>
          </div>
          
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)} className={classes.buttonStyle}>
            <ChevronLeftIcon />
          </IconButton>
          
        </div>
        <Divider style={ {height:0.1}}/>
        <List className={classes.containerWithScroll}>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>
        
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color="primary"
      >
        <Toolbar variant="dense" className={clsx(
                classes.toolbar,
                drawerOpen && classes.alignProfileIcon
              )}>      

          <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <IconButton
              edge="start"
              variant="contained"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="subtitle1" noWrap style={{ marginLeft: "10px" }}>
              Olá, {auth ? auth.name : "Username"}
            </Typography>
          </div>


          <div>
            <Tooltip title ={notifications.length ? newNotifications : noNotifications}>
              <IconButton
                aria-label="notifications of the app"
                aria-controls="notifications"
                aria-haspopup="true"
                onClick={handleMenuNotifications}
                variant="contained"
                edge="end"
                style={{ color: "white" }}
              >
                
                <Badge badgeContent={notifications.length} color="primary">
                  <NotificationsIcon />
                </Badge>

              </IconButton>
            </Tooltip>
            
            <Menu
              id="notifications"
              anchorEl={anchorElNotifications}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuNotificationsOpen}
              onClose={handleCloseMenuNotifications}
              PaperProps={{
                style: {
                  width: '400px',
                  height: 'auto',
                  maxHeight: '334.1px', 
                },
              }}
            >
              <div className="MuiStack-root mui-6coksm" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h6
                  style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "18px" }}
                  className="MuiTypography-root MuiTypography-h6 mui-6frkce"
                >
                  Notificações
                </h6>
                <IconButton>
                  <MailOutlineIcon />
                </IconButton>
              </div>

              <div
                style={{
                  maxHeight: '270px',  
                  overflowY: 'auto',    
                }}
              >
                {notifications.map((notification) => (
                    <div key={notification.id} style={{ display: 'flex', alignItems: 'center', padding: '10px 20px', borderBottom: '1px solid #e0e0e0', justifyContent: 'space-between'}}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '20px' }}>
                          <MessageIcon />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                            {notification.title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '4px' }}>
                            {notification.content}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {formatDate(notification.createdAt)}
                          </Typography>
                        </div>
                      </div>
                      <IconButton onClick={() => handleDelete(notification.id)} size="small">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}

              </div>
            </Menu>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              edge="end"
              style={{ color: "white" }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >             
              <MenuItem onClick={handleOpenUserModal}>
                 Perfil
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Outlet />
      </main>
    </div>
  );
};

export default LoggedLayout;
