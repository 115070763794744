import * as React from 'react';
import { Card, InputAdornment, OutlinedInput, Button, Grid, Modal } from '@material-ui/core';
import { MagnifyingGlass as MagnifyingGlassIcon, Plus as PlusIcon } from '@phosphor-icons/react';
import CustomerModal from './CustomerModal';

export function CustomersFilters({ onSearch, onAdd }) {
  const [open, setOpen] = React.useState(false);

  const handleAdd = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <>
      <Card style={{ padding: 15, marginBottom: 12, borderRadius: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <OutlinedInput
          defaultValue=""
          fullWidth
          placeholder="Procurar Cliente"
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
            </InputAdornment>
          }
          style={{ maxWidth: '500px', borderRadius: 5, height: 45 }}
          onChange={handleSearch}
        />
        <Grid style={{ marginRight: 35 }}>
          <Button color="primary" startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleAdd}>
            Adicionar
          </Button>
        </Grid>
      </Card>
      <Modal open={open} onClose={handleClose} style={{ marginTop: 150 }}>
        <CustomerModal onClose={handleClose} onAdd={onAdd} />
      </Modal>
    </>
  );
}
