'use client';

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { TextField, useTheme } from '@material-ui/core';
import { useAuthContext } from '../../contexts/UserContext.js';
import useAuth from '../../hooks/useAuth/index.js';

function formatCurrency(value) {
  return value
    .replace(/\D/g, '') // Remove tudo que não é dígito
    .replace(/(\d)(\d{2})$/, '$1,$2') // Adiciona a vírgula antes dos dois últimos dígitos
    .replace(/(?=(\d{3})+(\D))\B/g, '.') // Adiciona pontos a cada três dígitos
    .replace(/^/, 'R$ '); // Adiciona o símbolo de Real no começo
}

function parseCurrencyToNumber(value) {
  return parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.')) || 0;
}

export function AccountDetails() {
  const theme = useTheme();
  const { auth, setAuth } = useAuthContext();
  const { editUser } = useAuth();

  const [formValues, setFormValues] = useState({
    id: auth.id || '',
    name: auth.name || '',
    lastName: auth.lastName || '',
    email: auth.email || '',
    celular: auth.celular || '',
    state: auth.state || '',
    city: auth.city || '',
    value: auth.value || 100,
    password: auth.password
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValorChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      value: formattedValue, // Atualiza o campo "value" com a formatação
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formValuesToSend = {
      ...formValues,
      value: parseCurrencyToNumber(formValues.value), // Converte o valor para número
    };
    try {
      const response = await editUser(formValuesToSend);

      if (response) {
        setAuth(response);
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card elevation={0} style={{ border: `1px solid ${theme.palette.cardBorder}` }}>
        <CardHeader subheader="Informações de Contato" title="Perfil" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth required>
                <TextField
                  label="Primeiro Nome"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                  required
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Segundo Nome"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  required
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth required>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="psicofacil@gmail.com"
                  value={formValues.email}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Telefone"
                  name="celular"
                  value={formValues.celular}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Estado"
                  name="state"
                  value={formValues.state}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Cidade"
                  name="city"
                  value={formValues.city}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Valor Cobrado"
                  name="value"
                  value={formValues.value}
                  onChange={handleValorChange}
                  placeholder="R$100,00"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}
