
import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import ExtensionIcon from '@material-ui/icons/Extension';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useSelection } from '../../hooks/useSelection';
import { Grid, IconButton, TableContainer, Tooltip, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    statusRow:{
    }
}));

const rows = [
     {
        id:1,
        date:"15/07/09 15:24",
        description: "Atualizar frequência Paciente"
     },
     {
        id:2,
        date:"16/07/09 15:24",
        description: "Atualizar Dashboard"
     },
]
export default function CustomerNews({
  count = 0,
}) {
  const rowIds = React.useMemo(() => {
    return rows.map((customer) => customer.id);
  }, [rows]);

  const theme = useTheme();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  function renderActionRow({row}){
    return (
      <Box style={{ display: 'flex', gap: '0px' }}>
        <Tooltip title="Expandir">
          <IconButton color='primary' style={{padding: 4}} onClick={() => console.log(row)}>
            <ExtensionIcon />
          </IconButton>
        </Tooltip>

      </Box>
    );
  }
  return (
    <Card style={{borderRadius:7, height:'100%'}}>
         <Typography> Sem atividades para mostrar</Typography>
 
    </Card>
  );
}
