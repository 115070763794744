'use client';

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { TextField, useTheme } from '@material-ui/core';
import { useAuthContext } from '../../contexts/UserContext.js';
import useAuth from '../../hooks/useAuth/index.js';

export function ChangePassword() {
  const theme = useTheme();
  const { auth, setAuth } = useAuthContext();
  const { changePassword } = useAuth(); // Supondo que você tenha uma função para alterar a senha no hook de autenticação

  const [firstPassword, setFirstPassword] = useState(auth.password);
  const [secondPassword, setSecondPassword] = useState(auth.password);
  const [error, setError] = useState('');
  const { editUser } = useAuth();

  const [formValues, setFormValues] = useState({
    id: auth.id || '',
    name: auth.name || '',
    lastName: auth.lastName || '',
    email: auth.email || '',
    celular: auth.celular || '',
    state: auth.state || '',
    city: auth.city || '',
    value: auth.value || 100,
    password: auth.password
  });


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validação de senha
    if (firstPassword !== secondPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    setFormValues((prevState) => ({
      ...prevState,
      password: firstPassword,
    }));

    

    try {
      const response = await editUser(formValues);

      if (response) {
        setAuth(response);
        setError('');
      }
    } catch (error) {
      console.error('Erro ao mudar a senha:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card elevation={0}>
        <CardHeader subheader="Atualizar senha" title="Senha" />
        <CardContent>
          <Grid container direction='column' spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth required>
                <TextField
                  type="password"
                  label="Nova Senha"
                  value={firstPassword}
                  onChange={(e) => setFirstPassword(e.target.value)} // Atualiza o estado da primeira senha
                  required
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth required>
                <TextField
                  type="password"
                  label="Confirmar Senha"
                  value={secondPassword}
                  onChange={(e) => setSecondPassword(e.target.value)} // Atualiza o estado da segunda senha
                  required
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" color='primary'>Salvar</Button>
        </CardActions>
      </Card>
    </form>
  );
}
