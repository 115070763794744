import api from "../../api";

const useAppointment = () => {

  const get = async (idUser) => {
    try {
      const { data } = await api.get(`/appointment/${idUser}`);
      return data;
    }catch(err){
        console.error(err)
    }
  };

  return {
    get
  };

};

export default useAppointment;
