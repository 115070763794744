import React from 'react';
import Chart from 'react-apexcharts';

const FinancialChart = () => {
  const series = [
    {
      name: 'Receitas não pagas',
      data: [2000, 3000, 2000, 2500, 3000, 3500, 4000, 3500, 3000, 4000, 3500, 3000],
    },
    {
      name: 'Receitas pagas',
      data: [1500, 2000, 3000, 2500, 2000, 3000, 3000, 3000, 3500, 3000, 3500, 3000],
    },
    {
      name: 'Despesas não pagas',
      data: [1000, 500, 1500, 1000, 2000, 1500, 2000, 1500, 1000, 1500, 1000, 1500],
    },
    {
      name: 'Despesas pagas',
      data: [500, 1000, 1000, 1500, 1500, 1000, 1500, 2000, 1500, 2000, 1500, 2000],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
      ],
    },
    legend: {
      position: 'bottom',
      offsetY: 0,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      align: 'left',
    },
    colors: ['#4caf50', '#81c784', '#e57373', '#f44336'], // Cores personalizadas
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default FinancialChart;
