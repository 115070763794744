import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, Select, MenuItem, FormControl, InputLabel, Typography, Card, CardContent, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FinancialHeader from './financialHeader';
import FinancialChart from './financialChart';
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@mui/material/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: 'solid',
    borderLeftWidth: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: '100%',
    position: 'relative',
    borderColor: '#4caf50', // Cor da borda
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 48, // Tamanho maior do ícone
    color: '#4caf50', // Cor do ícone
    backgroundColor: '#EDEDED',
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleAmountContainer: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#4caf50',
  },
  amount: {
    fontSize: '1.8rem',
    fontWeight: 700,
    color: '#333', 
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.15rem',
    color: '#888',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  amount: {
    fontSize: '18px',
    fontWeight: 700,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: '0.875rem',
    color: '#888',
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  periodContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  periodText: {
    marginRight: theme.spacing(1),
    fontWeight: 600,
  },
  formControl: {
    minWidth: 120,
    marginLeft: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  chartContainer: {
    padding: theme.spacing(3),
    background: '#f7f7f7',
    borderRadius: 4,
    marginTop: theme.spacing(3),
  },
  dataTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600
  }
}));

function FinancialCardResult({ title, amount, footerLeft, footerRight, icon: Icon }) {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ borderColor: '#000000' }}> 
      <CardContent>
        <div className={classes.contentContainer}>
          <Icon className={classes.icon} style={{ color: '#000000', backgroundColor: '#EDEDED' }} />
          <div className={classes.titleAmountContainer}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h5" className={classes.amount}>
              {amount}
            </Typography>
          </div>
        </div>
      </CardContent>
      <div className={classes.footer}>
        <Typography variant="h6" className={classes.amount}>
          {footerLeft}
        </Typography>
        <Typography className={classes.amount}>
          {footerRight}
        </Typography>
      </div>
    </Card>
  );
}

function FinancialCardRevenue({ title, amount, footerLeft, footerRight, icon: Icon }) {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ borderColor: '#4caf50' }}> 
      <CardContent>
        <div className={classes.contentContainer}>
          <Icon className={classes.icon} style={{ color: '#4caf50' , backgroundColor: '#EDEDED' }} />
          <div className={classes.titleAmountContainer}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h5" className={classes.amount}>
              {amount}
            </Typography>
          </div>
        </div>
      </CardContent>
      <div className={classes.footer}>
      <Typography variant="h6" className={classes.amount}>
          {footerLeft}
        </Typography>
        <Typography className={classes.amount}>
          {footerRight}
        </Typography>
      </div>
    </Card>
  );
}

function FinancialCardExpenditure({ title, amount, footerLeft, footerRight, icon: Icon }) {
  const classes = useStyles();
  

  return (
    <Card className={classes.card} style={{ borderColor: '#f44336' }}> 
      <CardContent>
        <div className={classes.contentContainer}>
          <Icon className={classes.icon} style={{ color: '#f44336', backgroundColor: '#EDEDED' }} />
          <div className={classes.titleAmountContainer}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h5" className={classes.amount}>
              {amount}
            </Typography>
          </div>
        </div>
      </CardContent>
      <div className={classes.footer}>
        <Typography variant="h6" className={classes.amount}>
          {footerLeft}
        </Typography>
        <Typography className={classes.amount}>
          {footerRight}
        </Typography>
      </div>
    </Card>
  );
}
function FinancialControls({ onToggleVisibility, valuesVisible }) {
  const classes = useStyles();
  const [endDate, setEndDate] = useState("");
  const [initialDate, setInitalDate] = useState("");

  const handleInitialDateChange = (event) => {
    setInitalDate(event.target.value);
  };

  const handleEndlDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <Grid container alignItems="center" spacing={2} className={classes.controlsContainer}>
      <Grid item className={classes.periodContainer}>
        <Typography variant="subtitle1" className={classes.periodText}>
          Período:
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
        <Stack direction="row" spacing={2}>
          <TextField
              placeholder="Data"
              type="date"
              value={initialDate}
              onChange={handleInitialDateChange}
              style={{width: '40%'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
          />
          <Typography style={{fontSize:'40px'}}>-</Typography>
          <TextField
              placeholder="Data"
              type="date"
              value={endDate}
              onChange={handleEndlDateChange}
              style={{marginRight: '10px', width: '40%'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
        </Stack>
        </div>
        <IconButton className={classes.iconButton} onClick={onToggleVisibility}>
          {valuesVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Grid>

      <Grid item className={classes.rightButtons}>
        <Button variant="contained" color="primary" startIcon={<RefreshIcon />} className={classes.button}>
          Atualizar
        </Button>
      </Grid>
    </Grid>
  );
}

export default function Financial() {
  const classes = useStyles();
  const [valuesVisible, setValuesVisible] = React.useState(true);

  const handleToggleVisibility = () => {
    setValuesVisible(!valuesVisible);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <Typography variant="subtitle1" className={classes.dataTitle} >Financeiro</Typography>
      </Grid>

      <Grid item xs={12}>
        <FinancialControls
          onToggleVisibility={handleToggleVisibility}
          valuesVisible={valuesVisible}
        />
      </Grid>
      <Grid container spacing={8} style={{margin: '5px 10px 0px 10px'}}>
      <Grid item xs={12} md={4}>
        <FinancialCardResult
            title="Resultado previsto"
            amount={valuesVisible ? "R$ 1.000,00" : "******"}
            footerLeft="Resultado atual:"
            footerRight={(valuesVisible ? " R$ 2000,00" : "******")}
            icon={AttachMoneyIcon}
          />
      </Grid>
      <Grid item xs={12} md={4}>
      <FinancialCardRevenue
          title="Receita prevista"
          amount={valuesVisible ? "R$ 1.000,00" : "******"}
          footerLeft="Resultado atual:"
          footerRight={(valuesVisible ? " R$ 2000,00" : "******")}
          icon={TrendingUpIcon}
        />
      </Grid>
      <Grid item xs={12} md={4}>
      <FinancialCardExpenditure
          title="Despesas previstas"
          amount={valuesVisible ? "R$ 1.000,00" : "******"}
          footerLeft="Resultado atual:"
          footerRight={(valuesVisible ? " R$ 2000,00" : "******")}
          icon={TrendingDownIcon}
        />
      </Grid>
      </Grid>


      <Grid container spacing={3} style={{marginTop:'40px'}}>
        <Grid item xs={12} >
          <Box>
            <Typography variant="subtitle1" style={{fontWeight: '600'}}>Resultado previsto (Receitas e Despesas): Ano 2022</Typography>
          </Box>
          <Box className={classes.chartContainer}>
              <FinancialChart />  
          </Box>
        </Grid>
      
      </Grid>
    </Grid>
  );
}
