import * as React from 'react';
import { Button, Container, Typography, Grid } from '@material-ui/core';
import { Download as DownloadIcon, Upload as UploadIcon } from '@phosphor-icons/react';
import useCustomer from '../../hooks/useCustomer/index.js';
import { CustomersFilters } from './CustomersFilters';
import { CustomersTable } from './CustomersTable';
import { useAuthContext } from '../../contexts/UserContext.js';

export default function Customers() {
  const {search} = useCustomer();
  const {deleteCustomer} = useCustomer();
  const { auth, setAuth } = useAuthContext();
  const [customers, setCustomers] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');

  React.useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await search(auth.id);
      setCustomers(response);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleAdd = (customer) => {
    setCustomers([...customers, customer]);
  };

  const handleUpdate = (updatedCustomer) => {
    setCustomers(customers.map(customer => customer.id === updatedCustomer.id ? updatedCustomer : customer));
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteCustomer(id);
      setCustomers(customers.filter(customer => customer.id !== id));
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{ marginTop: 40, marginBottom: 15 }}>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>Pacientes</Typography>
        {/* <Grid item>
          <Button variant="outlined" startIcon={<UploadIcon />} style={{ marginRight: 10 }}>
            Upload
          </Button>
          <Button variant="contained" startIcon={<DownloadIcon />}>
            Download
          </Button>
        </Grid> */}
      </Grid>
      <CustomersFilters onSearch={handleSearch} onAdd={handleAdd} />
      <CustomersTable count={filteredCustomers.length} rows={filteredCustomers} onUpdate={handleUpdate} onDelete={handleDelete} />
    </Container>
  );
}
