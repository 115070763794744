import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Estilos
const useStyles = makeStyles((theme) => ({
  titleContainer: {
    backgroundColor: '#e0f7fa',
    padding: theme.spacing(1.5),
    borderRadius: 8,
    display: 'inline-block',
    border: '2px solid #0288d1',
    cursor: 'default',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  titleText: {
    color: '#0288d1',
    fontWeight: 600,
    fontSize: '1.2rem',
    textAlign: 'center'
  }
}));

// Componente FinancialHeader ajustado
function FinancialHeader() {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.titleText}>
            Painel Financeiro
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FinancialHeader;