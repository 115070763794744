import { useState } from 'react';

// material-ui
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// project import
import MainCard from '../../components/MainCard';
import IncomeAreaChart from './IncomeAreaChart';
import { makeStyles, useTheme } from '@material-ui/core';

// ==============================|| DEFAULT - UNIQUE VISITOR ||============================== //

const useStyles = makeStyles((theme) => ({
  dataTitle:{
    fontWeight:600
  }
}));

export default function UniqueVisitorCard({data}) {
  const [slot, setSlot] = useState('week');
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{marginBottom: 4}}>
        <Grid item>
          <Typography variant="subtitle1" className={classes.dataTitle} >Novas Consultas</Typography>
        </Grid>
        <Grid item>
          <Grid direction="row" alignItems="center" spacing={0}>
            <Button
              size="small"
              onClick={() => setSlot('month')}
              color={slot === 'month' ? "primary" : 'secondary'}
               variant={slot === 'month' ? 'contained' : 'text'}
            >
              Mês
            </Button>
            <Button
              size="small"
              onClick={() => setSlot('week')}
              color={slot === 'week' ? 'primary' : 'secondary'}
              variant={slot === 'week' ? 'contained' : 'text'}
            >
              Semana
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MainCard content={false} >
        <Box style={{paddingRight: 7}} >
          <IncomeAreaChart slot={slot} data={data} />
        </Box>
      </MainCard>
    </>
  );
}
