import api from "../../api";

const useCustomer = () => {


  const update = async (customerData) => {
    try {
      const { data } = await api.put(`/customer/${customerData.id}`, customerData);
      return data;
    }catch(err){
        console.error(err)
    }
  };
  const save = async (customerData) => {
    try {
      const { data } = await api.post("/customer/createCustomer", customerData);
      return data;
    }catch(err){
        console.error(err)
    }
  };
  const search = async (idUser) => {
    try {
      const { data } = await api.get(`/customer/${idUser}`);
      return data;
    }catch(err){
        console.error(err)
    }
  };
  const deleteCustomer = async (id) => {
    try {
      const { data } = await api.delete(`/customer/${id}`);
      return data;
    }catch(err){
        console.error(err)
    }
  };

  return {
    update,
    save,
    search,
    deleteCustomer
  };


};
export default useCustomer;
