
import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VideocamIcon from '@material-ui/icons/Videocam';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelection } from '../../hooks/useSelection';
import { Button, Grid, IconButton, TableContainer, Tooltip, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));


export function Documents({
    count = 0,
    rows = [],
}) {
    const classes = useStyles();
    const rowIds = React.useMemo(() => {
        return rows.map((customer) => customer.id);
    }, [rows]);

    const theme = useTheme();
    const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
    const selectedAll = rows.length > 0 && selected?.size === rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function renderActionRow({ row }) {
        return (
            <Box style={{ display: 'flex', gap: '0px' }}>
                <Tooltip title="Editar">
                    <IconButton style={{ padding: 4 }} onClick={() => console.log(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                    <IconButton style={{ padding: 4 }} color="error" onClick={() => console.log(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Acessar Sala Virtual">
                    <IconButton style={{ padding: 4 }} color="error" onClick={() => console.log(row)}>
                        <VideocamIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }
    function renderStatusRow(status) {
        const color = status === "Ativo" ? theme.palette.primary.main : theme.palette.danger
        return (
            <Grid container justifyContent='center' style={{ backgroundColor: color, padding: 2, borderRadius: 7 }}>
                <Typography variant='subtitle2' style={{ color: "#fff" }}>{status}</Typography>
            </Grid>
        );
    }
    return (
        <Card style={{ borderRadius: 7, height: '100%' }}>
            <Grid container style={{ padding: 10 }} alignItems='center' justifyContent='space-between' >
            <Box style={{display:'flex', alignItems:'center', gap:7}}  alignItems='center' item>
                     <AssignmentIcon />
                     <Typography variant="subtitle1" style={{ fontWeight: 700 }}>Documentos Salvos</Typography>

                </Box>
                <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                </label>
            </Grid>
            <Divider />

            <Box >
                <TableContainer style={{ maxHeight: '300px' }}>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Data de Criação</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                const isSelected = selected?.has(row.id);
                                return (
                                    <TableRow style={{ height: 45 }} hover key={row.id} selected={isSelected}>
                                        <TableCell>
                                            <Grid container style={{ alignItems: 'center', gap: 12 }} direction="row" spacing={1}>
                                                <Avatar src={row.avatar} />
                                                <Typography variant="subtitle2">{row.customer}</Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>{dayjs(row.scheduledDate).format('MMM D, YYYY')}</TableCell>
                                        <TableCell>{dayjs(row.scheduledTime).format('MMM D, YYYY')}</TableCell>
                                        <TableCell>
                                            {renderStatusRow(row.situation)}
                                        </TableCell>
                                        <TableCell>{renderActionRow(row)}</TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Divider />

        </Card>
    );
}
