import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options
const areaChartOptions = {
  chart: {
    height: 340,
    type: 'line',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 1.5
  },
  grid: {
    strokeDashArray: 4
  },
  xaxis: {
    type: 'datetime',
    categories: [
      '2018-01-19T00:00:00.000Z',
      '2018-02-19T00:00:00.000Z',
      '2018-03-19T00:00:00.000Z',
      '2018-04-19T00:00:00.000Z',
      '2018-05-19T00:00:00.000Z',
      '2018-06-19T00:00:00.000Z',
      '2018-07-19T01:30:00.000Z',
      '2018-08-19T02:30:00.000Z',
      '2018-09-19T03:30:00.000Z',
      '2018-10-19T04:30:00.000Z',
      '2018-11-19T05:30:00.000Z',
      '2018-12-19T06:30:00.000Z'
    ],
    labels: {
      format: 'MMM'
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    show: false
  },
  tooltip: {
    x: {
      format: 'MM'
    }
  }
};

const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getCurrentWeekNumber = () => {
  const today = new Date();
  return getWeekNumber(today);
};

const getAdjustedDayOfWeek = (date) => {
  const day = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  return day === 0 ? 6 : day - 1; // Adjust so Monday is 0, Sunday is 6
};


// ==============================|| REPORT AREA CHART ||============================== //

export default function ReportAreaChart({data}) {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.warning.main],
      xaxis: {
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      legend: {
        labels: {
          colors: 'grey.500'
        }
      }
    }));
  }, [primary, secondary, line, theme]);

  useEffect(() => {
    const paidData = [];
    const paidByMonth = Array(12).fill(0);

    data.forEach(({ appointmentDate, paid, value }) => {
      const date = new Date(appointmentDate);
      const month = date.getMonth(); // 0 (Jan) to 11 (Dec)

      if (paid) {
        paidByMonth[month] += value;
      } 
    });

    paidData.push(...paidByMonth);
  

    setSeries([
      {
        name: 'Series 1',
        data: paidData
      },
    ]);
  }, [ data]);

  const [series, setSeries] = useState([
    {
      name: 'Series 1',
      data: [58, 115, 28, 83, 63, 75, 35, 55]
    }
  ]);

  return <ReactApexChart options={options} series={series} type="line" height={334} />;
}
