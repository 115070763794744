import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { AccountDetails } from './AccountDetails';
import { AccountInfo } from './AccountInfo';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ChangePassword } from './ChangePassword';
import { useAuthContext } from '../../contexts/UserContext.js';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Inter',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  padding: {
    padding: theme.spacing(2),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  dataTitle: {
    fontWeight: 600,
    marginBottom:15
  }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default function Configuration() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { auth, setAuth } = useAuthContext();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Grid spacing={3}>
       <Typography variant="subtitle1" className={classes.dataTitle} >Configurações</Typography>
       <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab   label="Informações do Usuário" />
          <AntTab label="Senha" />
          <AntTab label="Documentos" />
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
    <TabPanel value={value} index={0}>
      <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountInfo />
            </Grid>
            <Grid item lg={7} md={6} xs={12}>
              <AccountDetails />
            </Grid>
          </Grid>
    </TabPanel>
    <TabPanel value={value} index={1}>
        <ChangePassword />
       
    </TabPanel>
    </div>



    </Grid>
  );
}
