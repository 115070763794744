


import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelection } from '../../hooks/useSelection';
import { Grid, IconButton, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import { ScheduledSessionTable } from "./ScheduledSessionTable";
import SystemNews from './SystemNews';
import { Documents } from './Documents';
import { TasksManagement } from './TasksManagement';

const sessions = [
    {
        id: 'USR-010',
        customer: 'Alcides Antonio',
        avatar: '/assets/avatar-10.png',
        situation: 'payed',
        scheduledTime: dayjs().subtract(2, 'hours').toDate(),
        scheduledDate: dayjs().subtract(2, 'hours').toDate(),
    },
    {
        id: 'USR-011',
        customer: 'Alcides Antonio',
        avatar: '/assets/avatar-10.png',
        situation: 'payed',
        scheduledTime: dayjs().subtract(2, 'hours').toDate(),
        scheduledDate: dayjs().subtract(2, 'hours').toDate(),
    },

];
export default function Clinic() {

    return (
        <Grid container spacing={4}>
            <Grid xs={7} item style={{height: "400px"}}>
                <ScheduledSessionTable rows={sessions} />
            </Grid>
            <Grid xs={5} item>
                <TasksManagement/>
            </Grid>
            <Grid xs={6} item style={{height: "400px"}}>
                <SystemNews />
            </Grid>
            <Grid xs={6} item style={{height: "466px"}}>
                <Documents />
            </Grid>
        </Grid>
    )

}