import * as React from 'react';
import Card from '@material-ui/core/Card';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, createStyles, makeStyles } from '@material-ui/core';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import createEditEvent from '../../pages/calendar/createEditEvent';
import { CalendarContext } from '../../util/CalendarContext';



const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
          },
        select: {
            "& .MuiOutlinedInput-input":{
                padding: "15px"

            },
            padding: 0
        },
}));

const statusOptions = [
    "Todos","Ativo", "Agendado", "Cancelado"
]
export function SchedulerFilter({ statusFilter, setStatusFilter }) {
    const classes = useStyles();
    const { stateCalendar, setStateCalendar } = React.useContext(CalendarContext)
    const { selectedDate, locale, layout, defaultEventDuration } = stateCalendar


    const changeStatusFilter = (event) => {
        setStatusFilter(event.target.value)
    }
  

    return (
        <Card style={{ padding: 15, marginBottom: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 80 }}>

        <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel >Status</InputLabel>
                <Select
                    className={classes.select}
                    value={statusFilter}
                    onChange={changeStatusFilter}
                    label="Status"
                >
                {statusOptions.map(option =>
                    <MenuItem  value={option}>{option}</MenuItem>
                )}
                
                </Select>
            </FormControl>
            <Grid >
                <Button color='primary' startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained"   
                onClick={(eventEl) =>
                        createEditEvent({
                            eventEl,
                            defaultEventDuration,
                            stateCalendar,
                            setStateCalendar,
                        })
                    }>
                    Adicionar Sessão
                </Button>
            </Grid>
        </Card>
    );
}
